import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { App as CapacitorApp } from '@capacitor/app';
import { setToken } from '@/utils';
import { IN_APP } from '@/config/env';
import { Toast } from 'vant';
export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  data() {
    return {
      userInfoLoaded: false
    };
  },

  async created() {
    this.$store.dispatch('getUserInfo').then(() => {
      this.userInfoLoaded = true;
    });
    this.$store.dispatch('getAppConfig').then(res => {
      if (res.grayMode) {
        document.querySelector('body').style.filter = 'grayscale(100%)';
      }
    });
    this.$store.dispatch('getFavoriteGoods');
    this.$store.dispatch('getCategory');
    this.$store.dispatch('getMyTodoCount');
    this.$store.dispatch('LoadMerchantShopingCardData');
    this.listenerBackButton();
  },

  methods: {
    // 退出应用程序
    async listenerBackButton() {
      let lastBackTime = 0;
      CapacitorApp.addListener('backButton', ({
        canGoBack
      }) => {
        if (!canGoBack) {
          if (new Date().getTime() - lastBackTime < 2000) {
            CapacitorApp.exitApp();
          } else {
            lastBackTime = new Date().getTime();
            Toast({
              position: 'bottom',
              message: '再按一次退出~'
            });
          }
        } else {
          lastBackTime = 0;
        }
      });
    }

  }
};